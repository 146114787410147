import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import DatoArticle from "../components/DatoArticle"

export default function Blog(props) {
  const { data } = props

  console.log(data)

  return (
    <Layout pageTitle={data.datoCmsArticle.title}>
      <DatoArticle data={data} />
      {/* <DatoBlog data={data} /> */}
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    datoCmsArticle(id: { eq: $id }) {
      id
      author
      category
      content
      excerpt
      title
      slug
      position
      originalId
      meta {
        createdAt
      }
    }
  }
`
