import React from "react"
import classes from "./index.module.scss"
import Image from "gatsby-image"
import BlogComponent from "../blogcomponent/blogcomponent"

const index = ({
  data: {
    datoCmsArticle: {
      id,
      title,
      slug,
      author,
      category,
      excerpt,
      position,
      content,
      meta
    }
  }
}) => {
  const dateEdited = meta.createdAt
  return (
    <>
      <section className={classes.root}>
        <div className={classes.articleHeader}>
          <div className={classes.titleContainer}>
            <h1 className={classes.category}>{category}</h1>
            <h1 className={classes.title}>{title}</h1>
            <span className={classes.meta}>
              <p className={classes.author}>{author}</p>|
              <p className={classes.date}>Last updated {dateEdited}</p>
            </span>
          </div>
        </div>

        <div
          className={classes.contentWrapper}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </section>

      {/* <section className={classes.root}>
        <div className={classes.headerContainer}>
          <Image className={classes.image} fluid={featuredImage.fluid} />

          <div className={classes.titleContainer}>
            <h1 className={classes.title}>{title}</h1>
            <span className={classes.subtitle}>
              <h6 className={classes.author}>{authorName}</h6>
              <h6 className={classes.date}>{date}</h6>
            </span>
          </div>
        </div>

      </section>
      <BlogComponent blogPage={true} /> */}
    </>
  )
}

export default index
